export default {
  "bind": {
    "sendOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send OTP Verification Code"])},
    "bindMemberPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bind Phone"])},
    "invalidOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid or expired OTP"])},
    "accountNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account not found"])},
    "failedToLoadCaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load captcha image, please try again later"])},
    "closeWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please close this window"])}
  },
  "common": {
    "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again later"])},
    "networkError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network connection error, please check your network status"])}
  }
}